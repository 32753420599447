<template>
  <div id="center">
    <tmpl_header :hide="['nav']" />

    <div class="container">
      <sidebar act="" />

      <div class="main">
        <!--个人页面-->
        <div class="user">
          <img src="@/assets/demo/user.png" class="avatar" />
          <span>{{ userInfo.customerNickname }}</span>
          <i>云方会员</i>
        </div>
        <!--总体概况-->
        <section class="card total">
          <div class="points">
            <strong>0</strong>
            <router-link to="/center/points" class="bright"
              >积分明细</router-link
            >
          </div>
          <div class="orders">
            <router-link to="/center/orders?status=1" class="unpay">
              <i>{{ status.count1 }}</i
              >待付款
            </router-link>
            <router-link to="/center/orders?status=3" class="unrec">
              <i>{{ status.count3 }}</i
              >待收货
            </router-link>
            <router-link to="/center/reviews" class="uncom">
              <i>{{ status.count6 }}</i
              >待评价
            </router-link>
          </div>
        </section>
        <!--订单-->
        <section class="card orders">
          <div class="title">
            <h3>我的订单</h3>
            <div class="opts">
              <router-link to="/center/orders" class="more"
                >查看全部</router-link
              >
            </div>
          </div>
          <ul>
            <li v-if="orders.length == 0" class="empty">还没有任何订单</li>
            <li v-for="order in orders" :key="order.orderId">
              <p><img :src="order.goodsMap[0].goodsImg" /></p>
              <p>
                <strong>{{ order.goodsMap[0].goodsName }}</strong>
                <span>订单号：{{ order.orderCode }}</span>
              </p>
              <p>
                <strong>{{ order.shippingPerson }}</strong>
              </p>
              <p>
                <strong>{{ order.createTime }}</strong>
              </p>
              <p>
                <strong>￥{{ formatPrice(order.orderOldPrice) }}</strong>
                <span>在线支付</span>
              </p>
              <p>
                <strong>{{ orderStatus(order.orderStatus) }}</strong>
                <router-link :to="'/center/order?order=' + order.orderId"
                  >订单详情</router-link
                >
              </p>
              <p>
                <a
                  v-for="opt in orderOperates(order.orderStatus)"
                  :key="opt.code"
                  :data-code="opt.code"
                  :data-id="order.orderId"
                  class="btn"
                  :class="
                    opt.code == 'pay' || opt.code == 'delivery'
                      ? 'btn-primary'
                      : 'btn-info'
                  "
                  @click="orderOperate"
                  >{{ opt.name }}</a
                >
              </p>
            </li>
          </ul>
        </section>
        <!--收藏-->
        <section class="card favors">
          <div class="title">
            <h3>我的收藏</h3>
            <div class="opts">
              <router-link to="/center/favor" class="more"
                >查看全部</router-link
              >
            </div>
          </div>
          <ul>
            <li v-if="favors.length == 0" class="empty">还没有任何收藏</li>
            <li v-for="pro in favors" :key="pro.goods_id">
              <router-link :to="'/goods/view/' + pro.goods_id" class="pro">
                <img :src="pro.goods_info_img_id" />
                <p>{{ pro.follow_price }}</p>
                <strong>{{ pro.goods_info_name }}</strong>
              </router-link>
            </li>
          </ul>
        </section>
      </div>
    </div>

    <tmpl_footer style="margin-top: 0" />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,

      userInfo: {},
      status: [],
      orders: [],
      favors: [],
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center") },
      });

    api.all([this.loadData(), this.loadStatus()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {
      return api.post(apis.center.index, {}).then((res) => {
        this.orders = res.myOrder;
        this.favors = res.myFollow;
        this.userInfo = res.customerInfo;
      });
    },

    loadStatus: function () {
      return api.post(apis.center.orders_count, {}).then((res) => {
        this.status = res;
      });
    },

    formatPrice(price) {
      let fen = (price * 100) % 100;
      return Math.floor(price) + "." + (fen < 10 ? "0" : "") + fen;
    },

    orderStatus(status) {
      return apis.orderStatus(status);
    },

    orderOperates(status) {
      return apis.orderOperates(status);
    },

    orderOperate(e) {
      var data = e.currentTarget.dataset,
        id = data.id,
        code = data.code;
      switch (code) {
        case "pay":
          this.$router.push({
            path: "/cart/pay",
            query: { order: id },
          });
          break;

        case "ship":
          this.$router.push({
            path: "/center/ship",
            query: { order: id },
          });
          break;

        case "delivery":
          break;
      }
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./index.less");
</style>
